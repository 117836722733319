<template>
  <div>
    <WLHYimportVehicle />
  </div>
</template>
<script>
import WLHYimportVehicle from "@/views/boss/transport/vehicleManage/WLHYimportVehicle";
export default {
  data() {
    return {

    }
  },
  components: {
    WLHYimportVehicle,
  },
}
</script>